import { NAME } from '../constants';
import { CURRENCY_SS_KEY } from '@app/account/constants';

// Utils
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Types
import type { Timeframe } from '../types';
import type { AppThunk } from '@core/redux/store';
import type { Currency } from '@app/account/types';

interface State {
    country?: string;
    currency?: Currency;
    timeframe: Timeframe;
}

const initialState: State = {
    country: undefined,
    currency: undefined,
    timeframe: undefined,
};

// Reducer
export const pricingSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setCountry: (state, action: PayloadAction<string>) => ({
            ...state,
            country: action.payload,
        }),
        setCurrency: (state, action: PayloadAction<Currency>) => ({
            ...state,
            currency: action.payload,
        }),
        setTimeframe: (state, action: PayloadAction<Timeframe>) => ({
            ...state,
            timeframe: action.payload,
        }),
        reset: () => initialState,
    },
});

// Actions
export const { setCountry, setCurrency, setTimeframe, reset } = pricingSlice.actions;

// Thunks

export const handleSetCurrency =
    (currencyCode: Currency): AppThunk =>
    async (dispatch, getState) => {
        const stateCurrency = getCurrency(getState());

        if (currencyCode !== stateCurrency) {
            dispatch(setCurrency(currencyCode as Currency));
            sessionStorage.setItem(CURRENCY_SS_KEY, currencyCode);
        }
    };

// Selectors
export const getCountry = (state): string => state[NAME]?.pricingReducer?.country;
export const getCurrency = (state): Currency => state[NAME]?.pricingReducer?.currency;
export const getTimeframe = (state): Timeframe => state[NAME]?.pricingReducer?.timeframe;

export default pricingSlice.reducer;
