// All the FE tracking events

// IMPORTANT: Use "proper case" for tracking events, e.g. "Something Something Clicked"

export const TRACKING_EVENTS = {
    signup: {
        geolocation: {
            fetched: '[Signup] Geolocation Fetched',
        },
    },
    user: {
        checkout: {
            seen: '[Signup] User Seen Checkout Form',
        },
    },
};
