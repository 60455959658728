// Utils
import Cookies from 'js-cookie';
import imgix from '@core/imgix';

// Hooks
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

// UI
import Image from 'next/image';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import LanguageButton from '@ui/components/LanguageSwitch/LanguageButton';

// Types
import type { FC } from 'react';

export interface Props {}

export type Language = 'de' | 'en';

const LanguageSwitch: FC<Props> = ({}) => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const { locale } = router;

    const handleLanguageSwitch = async (newLanguage: Language) => {
        const { pathname, query, asPath } = router;
        const cookiesOptions =
            process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
                ? { expires: 30, domain: '.perspective.co' }
                : {};
        Cookies.set('NEXT_LOCALE', newLanguage, cookiesOptions);
        await router.push({ pathname, query }, asPath, { locale: newLanguage, scroll: false });
    };

    const selectableLanguage = locale === 'de' ? 'en' : 'de';

    return (
        <div className="group absolute bottom-0 rounded-lg border border-gray-100/0 bg-white p-1 hover:border-gray-100/100 hover:shadow md:min-w-full">
            <LanguageButton language={selectableLanguage} onClick={handleLanguageSwitch} />
            <div className="flex cursor-pointer items-center rounded px-4 py-2 group-hover:bg-blue-50 group-hover:text-blue-500">
                <Image
                    src={`${locale === 'de' ? imgix.flags.de : imgix.flags.us}?auto=compress,format`}
                    alt={t(`language-${locale}`)}
                    width={22}
                    height={12}
                />
                <span className="ml-2">{t(`language-${locale}`)}</span>
                <div className="ml-1 flex size-4 items-center justify-center">
                    <ChevronDownIcon className="size-4 transform transition-transform duration-200 group-hover:rotate-180" />
                </div>
            </div>
        </div>
    );
};

export default LanguageSwitch;
