import { TestimonialData } from '@app/marketing/types';
import { PlanDetails } from '@app/pricing/types';

export interface ContentfulPageProps {
    title: string;
    description: string;
    testimonials?: TestimonialData[];
    logoUrls?: string[];
    planOverview?: PlanDetails[];
}

export interface ApiError {
    response: {
        data: {
            message: string;
            statusCode: number;
        };
    };
}

export interface TrackingObject {
    [key: string]: any;
}

export const enum LocalStorageValue {
    TRUE = 'true',
    FALSE = 'false',
    SEEN = 'seen',
}
