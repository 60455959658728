export const NAME = 'pricing';

export const FEATURE_TOOLTIP_ID = 'billing-feature-tooltip';

export const PLAN_IDS = {
    pro: 'ps-pro',
    business: 'ps-business',
    advanced: 'ps-agency',
};

export const BUNDLE_PLANS = {
    'starter-business': {
        planId: 'ps-business-12-month-crashcourse',
        couponId: 'CRASHCOURSE-BUSINESS',
        price: 997,
        originalPrice: 1788,
        accentColor: '#AD9762',
        slug: '/bundles/starter-business',
    },
    'starter-advanced': {
        planId: 'ps-agency-12-month-crashcourse',
        couponId: 'CRASHCOURSE-ADVANCED',
        price: 1997,
        originalPrice: 3588,
        accentColor: '#3C22A2',
        slug: '/bundles/starter-advanced',
    },
    'vsl-recruiting': {
        planId: 'ps-business-1-month-recruiting-agency',
        couponId: 'RECRUITING-AGENCY-VSL-BUSINESS',
        price: 29,
        slug: '/recruiting-agency-vsl',
    },
    'vsl-instant': {
        planId: 'ps-business-1-month-instant-recruiting',
        couponId: 'INSTANT-RECRUITING-VSL-BUSINESS',
        price: 29,
        accentColor: '#5856CE',
        slug: '/instant-recruiting-vsl',
    },
    'funnel-month-vsl': {
        planId: 'ps-business-1-month-recruiting-agency',
        couponId: 'RECRUITING-AGENCY-VSL-BUSINESS',
        price: 29,
        slug: '/funnel-month-vsl',
        accentColor: '#3C22A2',
    },
};

export const JOURNEYS_BUNDLE_PLANS = {
    business: {
        name: 'Business',
        slug: '/journeys/business',
        planId: 'ps-business-12-month',
        couponId: 'WINTER_RELEASE_BUNDLE_2024_12',
        price: 1251,
        originalPrice: 1788,
        accentColor: '#DEB864',
        trialEnd: 0,
    },
    advanced: {
        name: 'Advanced',
        slug: '/journeys/advanced',
        planId: 'ps-agency-12-month',
        couponId: 'WINTER_RELEASE_BUNDLE_2024_12_ADVANCED',
        price: 2511,
        originalPrice: 3588,
        accentColor: '#6F66FF',
        trialEnd: 0,
    },
};

export const SHARED_FUNNEL_PLAN_ID = 'ps-business-1-month';
