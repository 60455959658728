import { CRM_USER_LOGIN_URL, NAME } from '../constants';

// Utils
import Router from 'next/router';
import { identifyUser } from '@core/tracking';
import { apiPost, handleRuntimeError } from '@core/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    getIsAllowedToCreateSubscription,
    getPostLoginUrl,
    setTokensFromResponse,
} from '@app/account/utils';

// Redux
import { handleSetCurrency } from '@app/pricing/models/pricing';
import { fetchSubscription, setUser } from '@app/account/models/user';
import { setAccount } from '@app/account/models/signup';

// Types
import type { ApiError } from '@core/types';
import type { AppThunk } from '@core/redux/store';
import type { Currency, LoginArgs } from '@app/account/types';

interface State {
    loading: boolean;
    error: ApiError;
}

const initialState: State = {
    loading: false,
    error: null,
};

export const loginSlice = createSlice({
    name: `${NAME}-login`,
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setError(state, action: PayloadAction<ApiError>) {
            return {
                ...state,
                error: action.payload,
            };
        },
        reset: () => initialState,
    },
});

// Actions
export const { setLoading, setError, reset } = loginSlice.actions;

// Thunks
export const login =
    ({
        email,
        password,
        rememberMe,
        query,
        successRedirectUrl,
        welcomeUrl,
        isBundle,
    }: LoginArgs): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setLoading(true));
            dispatch(setError(null));

            // Send Login request
            const response = await apiPost('/login', {
                data: { email, password },
            });

            const isCrmUser = await validateIsCrmUser(email);

            if (isCrmUser) {
                await Router.push(CRM_USER_LOGIN_URL);

                return;
            }
            dispatch(setAccount({ email, password, name: '', planId: query.planId }));

            const subscriptionId = response?.data?.data?.attributes?.subscriptionId;

            let planIdFromSubscription;
            let subscriptionStatus;
            let addons = [];

            if (isBundle && subscriptionId) {
                const subscription = await fetchSubscription(subscriptionId);

                if (subscription) {
                    // Update currency in state & SS with currency from subscription
                    dispatch(
                        handleSetCurrency(
                            subscription.attributes.subscription.currency_code as Currency,
                        ),
                    );
                }

                planIdFromSubscription = subscription?.attributes?.subscription?.plan_id;
                subscriptionStatus = subscription?.attributes?.subscription?.status;
                addons = subscription?.attributes?.subscription?.addons;
            }

            const customerAllowedToCreateSubscription = getIsAllowedToCreateSubscription({
                addons,
                subscriptionPlanId: planIdFromSubscription,
                subscriptionStatus,
                bundleId: isBundle && query.planId,
            });

            // set Tokens
            setTokensFromResponse(response, rememberMe);

            // Monitoring & Tracking
            const userData = {
                id: response?.data?.data?.attributes?.trackingId,
                email,
                firstName: response?.data?.data?.attributes?.firstName,
                lastName: response?.data?.data?.attributes?.lastName,
            };

            identifyUser(userData, { segment: { ActiveCampaign: false } });
            dispatch(setUser(userData));

            // Redirect after login
            const nextStepUrl = getPostLoginUrl({
                successRedirectUrl,
                subscriptionId,
                customerAllowedToCreateSubscription,
                welcomeUrl,
                isBundle,
                query,
            });

            await Router.push(nextStepUrl);
        } catch (err) {
            handleRuntimeError(err, { message: 'Failed to login' });

            dispatch(
                setError(err.response ? err : { response: { data: { message: err.message } } }),
            );
        } finally {
            dispatch(setLoading(false));
        }
    };

const validateIsCrmUser = async (email: string) => {
    try {
        const userRes = await (
            await fetch('/api/user/get-by-email', {
                method: 'POST',
                body: JSON.stringify({ email }),
            })
        ).json();

        const userRole = userRes?.data?.attributes?.role;

        if (userRole === 'crm') {
            return true;
        }

        return false;
    } catch (err) {
        handleRuntimeError(err, { message: 'Failed to validate user role' });
    }
};

// Selectors
export const getLoading = (state): boolean => state[NAME]?.loginReducer?.loading;
export const getError = (state): ApiError => state[NAME]?.loginReducer?.error;

export default loginSlice.reducer;
